<template>
  <FormGroup
    class="o-form__input o-singlechoice"
    :class="{
    'is-valid': showValidity && valid,
    'is-invalid': showValidity && !valid
  }">
    <OFormLabel :for="htmlId" class="o-singlechoice__label"><slot></slot></OFormLabel>
    <FormButtons role="radiogroup" class="o-singlechoice__group" :id="htmlId">
      <o-button
        v-for="(option, key) in options"
        class="o-singlechoice__item"
        role="radio"
        theme="vradio"
        type="button"
        :aria-checked="(value === option.value).toString()"
        :key="key"
        @click="$emit('change', option.value, key, options)"
        :disabled="disabled"
      >
        <slot name="option" v-bind:option="option.value">{{option.label}}</slot>
      </o-button>
    </FormButtons>
  </FormGroup>
</template>

<script>
import htmlId from '@/mixins/htmlId'
import FormButtons from './FormButtons'
import OButton from '../OButton'
import FormGroup from './FormGroup'
import OFormLabel from './OFormLabel'

export default {
  name: 'OFormSingleChoice',
  components: { OFormLabel, FormGroup, OButton, FormButtons },
  mixins: [htmlId],
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: [String, Number, Object],
    options: [Array],
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      dirty: false,
      internalValue: null,
      valid: false,
      showValidity: false
    }
  }
}
</script>

<style lang="scss">
  .o-singlechoice__group {
    padding: 0;
    margin: 0;
    flex-flow: column nowrap;
  }
</style>
