<template>
  <label v-if="$slots.default" class="o-form__input__label">
    <slot></slot>
  </label>
</template>

<script>
export default {
  name: 'OFormLabel'
}
</script>

<style lang="scss">
  .o-form__input__label {
    text-transform: uppercase;
    padding: 0 0 0.25em;
  }
</style>
