<template>
  <div class="form-group">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'FormGroup'
}
</script>

<style lang="scss">
  .form-group {
    display: flex;
    flex-direction: column;
    margin: 0 0 $vertical-margin;
    align-items: stretch;
  }
</style>
