<template>
  <div :class="$style[`alert-${variant}`]" role="alert">
    <slot />
  </div>
</template>

<script>

export default {
  name: 'OAlert',
  props: {
    variant: []
  }
}
</script>

<style lang="scss" module>
$alert-bg-scale: -80% !default;
$alert-border-scale: -70% !default;
$alert-color-scale: 40% !default;
$alert-border-radius: $border-radius !default;

.alert {
  position: relative;
  padding: 1em 1em;
  margin-bottom: 1em;
  border: 1px solid transparent;
  border-radius: $alert-border-radius;
  text-transform: none;
  letter-spacing: normal;

  a {
    color: inherit;
    text-transform: none;
    letter-spacing: normal;
    font-weight: bold;
    text-decoration: underline;
  }
}

@mixin alert-variant($name, $value) {
  $alert-background: shift-color($value, $alert-bg-scale);
  $alert-border: shift-color($value, $alert-border-scale);
  $alert-color: shift-color($value, $alert-color-scale);

  @if (contrast-ratio($alert-background, $alert-color) < $min-contrast-ratio) {
    $alert-color: mix($value, color-contrast($alert-background), abs($alert-color-scale));
  }

  .alert-#{$name} {
    composes: alert;
    background-color: $alert-background;
    border-color: $alert-border;
    color: $alert-color;
  }
}

@include alert-variant('info', $primary);
// @include alert-variant('error', #f99);
@include alert-variant('success', #15b76c);

.alert-error {
  composes: alert;
  background-color: #f00;
  border-color: #f00;
  color: #fff;
}
</style>
