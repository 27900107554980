<template>
  <div>
    <div style="display: flex; justify-content: space-between;">
      <div>Amount:</div>
      <div></div>
    </div>
    <div style="display: flex; justify-content: space-between;">
      <div class="p-amount">{{ order.payin_amount }} {{ order.payin_currency }}</div>
      <div>
        <div v-if="showCopyAmount" class="p-toast">Copied!</div>
        <img @click="doCopyAmount()" width="16" class="copy2clipboard" src="../assets/copy2clipboard.svg">
      </div>
    </div>

    <div style="display: flex; justify-content: space-between;margin-top: 5px;">
      <div>To this address:</div>
      <div></div>
    </div>
    <div style="display: flex; justify-content: space-between;">
      <div style="word-break: break-all;"><strong>{{ order.crypto_address }}</strong></div>
      <div>
        <div v-if="showCopyAddress" class="p-toast">Copied!</div>
        <img @click="doCopyAddress()" width="16" class="copy2clipboard" src="../assets/copy2clipboard.svg">
      </div>
    </div>

    <div style="display: flex; justify-content: space-between;margin-top: 5px;">
      <div>Network: <br/>
        <strong>{{ order.network }}</strong>
      </div>
      <div>
        <qr-code v-if="paymentUrl" :text="paymentUrl" :size="100"></qr-code>
      </div>
    </div>

    <FormButtons v-if="paymentUrl" style="margin-bottom: 0">
      <OButton
        type="button"
        style="width: 100%;"
        @click="payInWallet"
      >
        Pay in wallet
      </OButton>
      <OButton
        theme="outline-primary"
        style="padding: 8px;"
        :class="showTooltip ? 'o-tooltip-container o-tooltip-trigger o-tooltip-show' : ''"
        @click="showTooltip=!showTooltip"
        @blur="showTooltip=false"
      >
        <img src="../assets/ic_info_primary.svg" style="height: 2em; float: right; margin-top: -5px">
        <o-tooltip anchor="right">
          <div style="text-align: left;">
            The “Pay in wallet“ button <br/>
            might not work! <br/>
            The button only works if you <br/>
            have a software wallet <br/>
            installed on your device <br/>
            that supports the network <br/>
            of your chosen payment currency.
          </div>
        </o-tooltip>
      </OButton>
    </FormButtons>

    <div style="display: flex; justify-content: space-between;">
      <div style="word-break: break-all;"></div>
      <div style="white-space: nowrap;"></div>
    </div>

    <div style="display: flex; justify-content: space-between;margin-top: 5px;">
      <div class="p-timer">{{ timer }}</div>
      <div style="margin-left: 10px">
        It’s a single-use wallet address (valid for {{ (order.crypto_delay / 60).toFixed(0) }} minutes only) for
        transferring funds and it relates to this order only.
      </div>
    </div>

    <OAlert v-if="!timerCount" variant="error">
      <strong>Your order has expired. Please make a new order.</strong>
    </OAlert>

    <OAlert variant="info">
      <strong>Deposit {{ order.payin_currency }} on the {{ order.network }} network.</strong>
      Deposits sent from other networks will be lost.
    </OAlert>

    <div style="display: flex; justify-content: space-between;margin-top: 5px;">
      <div style="word-break: break-all;">Order ID:</div>
      <div style="white-space: nowrap;"><strong>{{ order.client_order_id }}</strong></div>
    </div>

    <OForm @submit="sent">
      <div class="c-grid__item">
        <OFormError :message="error"></OFormError>
      </div>
      <FormButtons style="margin-top: 0.8rem">
        <OFormSubmitButton>
          I've Sent Funds
        </OFormSubmitButton>
      </FormButtons>
    </OForm>
  </div>
</template>

<script>

import OFormSubmitButton from '@/objects/forms/OFormSubmitButton.vue'
import OFormError from '@/objects/forms/OFormError.vue'
import FormButtons from '@/objects/forms/FormButtons.vue'
import OForm from '@/objects/forms/OForm.vue'
import OAlert from '@/objects/OAlert.vue'
import OButton from '@/objects/OButton.vue'
import OTooltip from '@/objects/OTooltip.vue'
import { mapActions } from 'vuex'
import Vue from 'vue'
import VueQRCodeComponent from 'vue-qrcode-component'

Vue.component('qr-code', VueQRCodeComponent)

export default {
  name: 'CSwitcherePayment',
  components: { OTooltip, OButton, OAlert, OForm, FormButtons, OFormError, OFormSubmitButton },
  props: {
    order: {
      type: Object, required: true
    }
  },
  data: () => (
    {
      error: null,
      showTooltip: false,
      showCopyAmount: false,
      showCopyAddress: false,
      timerCount: 0
    }
  ),
  computed: {
    paymentUrl () {
      return this.order.network_uri
    },
    timer () {
      return this.formatTime(this.timerCount)
    }
  },
  mounted () {
    this.timerCount = this.order.crypto_delay
  },
  methods: {
    ...mapActions({
      showToasts: 'toasts/show'
    }),
    sent () {
      this.$router.push('/switchere-order?id=' + this.order.client_order_id)
    },
    payInWallet () {
      window.location.href = this.paymentUrl
    },
    doCopyAmount () {
      this.$copyText(this.order.payin_amount)
      this.showCopyAmount = true
      let self = this
      setTimeout(function () {
        self.showCopyAmount = false
      }, 1000)
    },
    doCopyAddress () {
      this.$copyText(this.order.crypto_address)
      this.showCopyAddress = true
      let self = this
      setTimeout(function () {
        self.showCopyAddress = false
      }, 1000)
    },
    formatTime (seconds) {
      const minutes = Math.floor(seconds / 60)
      const remainingSeconds = seconds % 60
      // Add leading zeros if necessary
      const formattedMinutes = String(minutes).padStart(2, '0')
      const formattedSeconds = String(remainingSeconds).padStart(2, '0')
      return formattedMinutes + ':' + formattedSeconds
    }
  },
  watch: {
    timerCount: {
      handler (value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--
          }, 1000)
        }
      },
      immediate: true // This ensures the watcher is triggered upon creation
    }

  }
}
</script>

<style lang="scss">
.p-amount {
  font-size: 1.3em;
  font-weight: bold;
}

.p-timer {
  font-size: 1.2em;
  font-weight: bold;
  color: $primary;
}

.copy2clipboard {
  cursor: pointer;
}

.p-toast {
  position: absolute;
  margin-top: -30px;
  margin-left: -30px;
  border-radius: 4px;
  background-color: #333;
  padding: 4px;
  color: #ffffff;
  font-size: 0.8em;
}
</style>
