<template>
  <l-default>
    <OTitle>
      <div v-if="!order">Checkout</div>
      <div v-else>Payment</div>
    </OTitle>
    <OCRow class="u-mb-3" v-if="!order">
      <div>{{ email }}</div>
      <div class="u-ml-auto">{{ credits }} credits</div>
    </OCRow>

    <CVerifyEmail v-if="!isActive"></CVerifyEmail>

    <div v-if="isActive" class="u-mb-3">
      <div v-if="!order">
        <CBuyButton :credits="Number(parameters.credits)"
                    :bonus_credits="Number(parameters.bonus_credits)"
                    :cost="Number(parameters.amount)"
                    :always="isAlways" :isLink="false" class="c-grid__item"></CBuyButton>

        <OFormSingleChoiceV2
          style="margin-bottom: 0.5rem"
          v-model="currency"
          type="buttons"
          :options="currencyOptions"
          @change="changeCurrency"
        >
          Choose Crypto Currency:
        </OFormSingleChoiceV2>
        <div>
          <div style="display: flex; justify-content: space-between;">
            <div>RATE:</div>
            <div>$1 ≈ {{ rate }} {{ currency }}</div>
          </div>
          <div style="display: flex; justify-content: space-between;">
            <div>YOU SEND:</div>
            <div><strong>≈ {{ (parameters.amount * rate).toFixed(8) }} {{ currency }}</strong></div>
          </div>
        </div>
        <OForm @submit="submit">
          <div class="c-grid__item">
            <OFormError :message="error"></OFormError>
          </div>
          <FormButtons style="margin-top: 0.8rem">
            <OFormSubmitButton>
              PAY
            </OFormSubmitButton>
          </FormButtons>
        </OForm>
        <em>
          * Stay informed with our real-time exchange rate updates, ensuring you have the latest approximate value for
          your cryptocurrencies.
        </em>
      </div>
      <div v-else>
        <CSwitcherePayment :order="order"/>
      </div>
    </div>
  </l-default>
</template>

<script>
import LDefault from '@/layouts/LDefault'
import OTitle from '../../objects/OTitle'
import { mapGetters } from 'vuex'
import OCRow from '../../objects/containers/OCRow'
import CBuyButton from '../../components/CBuyButton'
import CVerifyEmail from '../../components/CVerifyEmail'
import OFormSingleChoiceV2 from '@/objects/forms/OFormSingleChoiceV2.vue'
import FormButtons from '@/objects/forms/FormButtons.vue'
import OFormSubmitButton from '@/objects/forms/OFormSubmitButton.vue'
import OFormError from '@/objects/forms/OFormError.vue'
import OForm from '@/objects/forms/OForm.vue'
import CSwitcherePayment from '@/components/CSwitcherePayment.vue'

const getApi = () => import('@/services/api')

export default {
  name: 'PageCreditCardCheckout',
  components: {
    CSwitcherePayment,
    OForm,
    OFormError,
    OFormSubmitButton,
    FormButtons,
    OFormSingleChoiceV2,
    LDefault,
    OTitle,
    CBuyButton,
    OCRow,
    CVerifyEmail
  },
  data: function () {
    return {
      currency: 'BTC',
      currencyOptions: [],
      currencyRates: {},
      rate: 0,
      error: null,
      order: null,
      interval: null,
      orderStatus: null
    }
  },
  computed: {
    ...mapGetters({
      credits: 'getCredits',
      isActive: 'isActive',
      email: 'getEmail'
    }),
    parameters: function () {
      return this.$route.query
    },
    isAlways: function () {
      return true
    }
  },
  methods: {
    async load () {
      const api = await getApi()
      this.showLoader()
      const result = await api.getSwitchereCurrencies({
        user: this.$store.getters.getEmail,
        pass: this.$store.getters.getPassword
      })
      this.hideLoader()
      if (result.error) {
        this.error = result.message
        return false
      }
      const currencies = result.data
      currencies.forEach((c) => {
        this.currencyOptions.push({ value: c.code, label: c.label })
        this.currencyRates[c.code] = c.rate
      }
      )
      this.changeCurrency()
    },
    changeCurrency () {
      this.rate = this.currencyRates[this.currency]
    },
    async submit () {
      this.error = ''
      const api = await getApi()
      const orderId = await this.getOrderId(api)
      if (!orderId) {
        return
      }
      this.order = await this.createSwitchereOrder(api, orderId)
      if (!this.order) {
        this.order = await this.createSwitchereOrder(api, orderId)
        if (!this.order) {
          return
        }
      }
      let self = this
      this.interval = setInterval(function () {
        self.loadOrderStatus()
      }, 30000)
    },
    async getOrderId (api) {
      this.showLoader()
      let credits = Number(this.parameters.credits)
      if (this.parameters.bonus_credits) {
        credits += Number(this.parameters.bonus_credits)
      }
      const result = await api.createOrder({
        user: this.$store.getters.getEmail,
        pass: this.$store.getters.getPassword,
        credits: credits,
        processor: 'switchere'
      })
      this.hideLoader()
      if (result.error) {
        this.error = result.message
        return false
      }

      return result.orderId
    },
    async createSwitchereOrder (api, orderId) {
      this.showLoader()
      const result = await api.createSwitchereOrder({
        user: this.$store.getters.getEmail,
        pass: this.$store.getters.getPassword,
        orderId: orderId,
        currency: this.currency
      })
      this.hideLoader()
      if (result.error) {
        this.error = result.message
        return false
      }

      return result.data
    },
    showLoader () {
      this.loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        color: '#f00',
        width: 125,
        height: 125
      })
    },
    hideLoader () {
      this.loader.hide()
    },
    async loadOrderStatus () {
      const api = await getApi()
      const result = await api.getSwitchereOrder({
        user: this.$store.getters.getEmail,
        pass: this.$store.getters.getPassword,
        id: this.order.client_order_id
      })
      if (result.error) {
        return
      }
      this.orderStatus = result.data
      if (this.orderStatus && this.orderStatus.status !== 'pending') {
        this.$router.push('/switchere-order?id=' + this.order.client_order_id)
      }
    }
  },
  mounted () {
    this.load()
  },
  beforeDestroy () {
    clearInterval(this.interval)
  }
}
</script>

<style lang="scss">
h2 {
  color: $primary;
}

.c-grid {
  display: flex;
  flex-flow: column nowrap;
  margin: 0 0 1rem;
}

.c-grid__title {
  margin: 0;
  padding: 0.25em 0;
  text-transform: uppercase;
}

.c-grid__item {
  margin: 0 0 0.5rem;
}

.u-ml-auto {
  margin-left: auto;
}

.u-mb-3 {
  margin-bottom: 1.5rem;
}
</style>
